import { useCart } from "hooks/cart/useCart";
import { useDebounce } from "hooks/useDebounce";
import { _axios } from "interceptor/http-config";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {  useNavigate } from "react-router-dom";
import { _AuthApi } from "api/auth";
const Cart = () => {
  const { t } = useTranslation("index");

  const { data } = useCart();
  const total = [
    { name: t("Subtotal"), value: data?.cart?.invoice_value },
    { name: t("Shipping"), value: data?.cart?.shipment_fees },
    { name: t("Coupon Discount"), value: data?.cart?.coupon_discount },
    { name: t("Total"), value: data?.cart?.total },
  ];
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [couponNumber, setCouponNumber] = useState("");
  const debouncedIncreaseQuantity = useDebounce(increaseQuantity, 1000);
  const debouncedDecreaseQuantity = useDebounce(decreaseQuantity, 1000);
  const queryClient = useQueryClient();
  const handleView = (id) => {
    navigate(`/product/view/${id}`);
  };
  const hanldeViewCheckOut = (id) => {
    navigate(`check-out`);
  };

  async function increaseQuantity(itemId) {
    try {
      const formData = new FormData();
          const session_id = localStorage.getItem("session_id");
          formData.append("session_id", session_id);
      formData.append("quantity", quantity);
      await _axios.post(`/user/cart/addProduct/${itemId}`, formData);
      queryClient.invalidateQueries("cart");
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  }

  async function decreaseQuantity(itemId) {
    try {
      const formData = new FormData();

      const session_id = localStorage.getItem("session_id");
      formData.append("session_id", session_id);
      formData.append("quantity", quantity);
      await _axios.post(`/user/cart/decreaseProduct/${itemId}`, formData);
      queryClient.invalidateQueries("cart");
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  }

  const handleIncrease = (itemId) => {
    debouncedIncreaseQuantity(itemId);
  };

  const handleDecrease = (itemId) => {
    debouncedDecreaseQuantity(itemId);
  };
  const access_token = _AuthApi.getToken();

  const deleteProduct = async (itemId) => {
    const formData = new FormData();

    const session_id = localStorage.getItem("session_id");
    formData.append("session_id", session_id);
    try {
      await _axios.post(`/user/cart/removeProduct/${itemId}`, formData, {
        headers: {
          Authorization: access_token ? `Bearer ${access_token}` : "",
        },
      });
      queryClient.invalidateQueries("cart");
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleCouponSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("coupon_number", couponNumber);
      formData.append("cart_id", data?.cart?.id);

      await _axios.post("/user/cart/checkCoupon", formData);
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  const isCart = window.location.pathname === "/cart";

  let setp1 = "";
  let setp2 = "";
  let step1Font = "";
  let step2Font = "";
  if (isCart) {
    setp1 = t("All Roses");
    setp2 = t("CheckOut");
    step1Font = "semibold";
    step2Font = "base";
  }

  return (
    <section className="mx-5 md:mx-20">
      <div className="flex mb-10 md:mb-20 gap-10">
        <h1 className={`font-${step1Font} text-2xl`}>1. {setp1}</h1>
        <h1 className={`font-${step2Font} text-2xl`}>2. {setp2}</h1>
      </div>
      <div>
        <h1 style={{ fontWeight: 800 }} className="bodoni text-3xl">
          {t("My Cart")}
        </h1>
      </div>
      <div className=" md:px-10 flex flex-col md:flex md:flex-row mt-10 gap-10 	">
        <div className="flex-1 ">
          <div className="flex flex-col gap-10 ">
            {data?.cart?.products?.map((item, index) => (
              <div
                key={index}
                className="  flex flex-row md:flex md:flex-row md:gap-40 w-1/1 md:w-full md:p-5  gap-10 leading-10 items-center  "
                style={
                  {
                    // boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.10)",
                  }
                }
              >
                <img
                  className=" w-28 h-28 md:w-60  md:h-60 rounded"
                  src={item.product_image}
                  alt=""
                  onClick={() => handleView(item.id)}
                />
                <div>
                  <p>{item.product_name}</p>
                  <div className="grid grid-cols-2   gap-10">
                    <div>
                      <p>{t("Quantity")}</p>
                    </div>
                    <div className=" flex justify-center gap-5 ">
                      <button
                        onClick={() => handleDecrease(item.id)}
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleIncrease(item.id)}>+</button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10">
                    <div>
                      <p className="text-secondary-color md:text-3xl font-semibold">
                        ${item.product_price}
                      </p>
                    </div>
                    <div className="flex justify-center items-center ">
                      <DeleteTwoToneIcon
                        sx={{ width: "40px", height: "30px" }}
                        className="text-secondary-color border border-3 border-secondary-color rounded cursor-pointer  "
                        onClick={() => deleteProduct(item.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 md:pl-10  flex flex-col gap-32 leading-10">
          <div className="flex flex-col gap-40">
            <form
              className="gap-3 md:gap-10 flex flex-col"
              onSubmit={handleCouponSubmit}
            >
              <div>
                <p>{t("Have a Coupon?")}</p>
              </div>
              <input
                placeholder={t("Enter Your Coupon Here")}
                className="border border-1 border-secondary-color p-4 md:w-1/2 rounded"
                value={couponNumber}
                onChange={(e) => setCouponNumber(e.target.value)}
              />
              <button
                type="submit"
                className="flex justify-center text-white bg-[#C1CB90] rounded md:p-3 gap-5 w-24"
              >
                {t("Apply")}
              </button>
            </form>
          </div>
          <div className="flex flex-col gap-10 md:mt-20">
            <div>
              <p>{t("Cart Totals")}</p>
            </div>
            <div className=" w-1/2">
              {total.map((item, index) => (
                <div className="grid grid-cols-2 leading-10">
                  <p>{item.name}</p>
                  <p>{item.value}</p>
                  {index === 2 && <hr className="border-black w-60" />}
                </div>
              ))}
            </div>
            <button
              className="flex justify-center text-white bg-[#C1CB90] rounded p-3 gap-5 "
              onClick={hanldeViewCheckOut}
            >
              {t("Check Out")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
