import { useQuery } from "react-query";
import { _Profile } from "api/profile/profile";

export const useWishlist = (id) => {
  const { data, isLoading } = useQuery(
    ["wishlist"],
    () => _Profile.wishlist().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
