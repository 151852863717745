import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { _AuthApi } from "api/auth";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
let schema = yup.object().shape({
  first_name: yup.string().required("Fisrt Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup.string().required("phone is required"),
  email: yup.string().required("Email is required"),
  address: yup.string().required("Address is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .min(6, "The confirm password must be of six characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("password")], "your password does not match"),
  createdOn: yup.date().default(function () {
    return new Date();
  }),
});
const Register = () => {
  const { t } = useTranslation("index");

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { mutate } = useMutation((data) => createPost(data));
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [value, setValue] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://18.208.110.42/user/city", {
          headers: ["en", "ar"].includes(localStorage.getItem("i18nextLng")), // Replace "en" with the desired language value
        });

        const data = response.data.cites;
        setOptions(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const handleSelectOption = (event) => {
    const selectedId = Number(event.target.value);
    const selectedLabel = event.target.options[event.target.selectedIndex].text;

    setSelectedOption({ id: selectedId, name: selectedLabel });
  };

  async function createPost(data) {
    const response = await axios;
    _AuthApi
      .register(data, setLoading, navigate, enqueueSnackbar)
      .finally(() => setLoading(false));
    setMessage(response.data);
  }

  const onSubmit = async (input) => {
    const formData = new FormData();
    const session_id = localStorage.getItem("session_id");
    formData.append("session_id", session_id);
    formData.append("first_name", input.first_name);
    formData.append("last_name", input.last_name);
    formData.append("email", input.email);
    formData.append("password", input.password);
    formData.append("confirm_password", input.confirm_password);
    formData.append("address", input.address);
    formData.append("phone", value);
    formData.append("city_id", selectedOption.id);
    mutate(formData);
  };
  const feilds = [
    {
      label: t("First Name"),
      type: "text",
      id: "first_name",
      name: "first_name",
      placeholder: t("Enter Your First Name"),
      register: "first_name",
      error: "first_name",
    },
    {
      label: t("Last Name"),
      type: "text",
      id: "last_name",
      name: "last_name",
      placeholder: t("Enter Your Last Name"),
      register: "last_name",
      error: "last_name",
    },

    {
      label: t("Email"),
      type: "email",
      id: "email",
      name: "email",
      placeholder: t("Enter Your Email"),
      register: "email",
      error: "email",
    },
    {
      label: t("Address"),
      type: "text",
      id: "address",
      name: "address",
      placeholder: t("Enter Your Address"),
      register: "address",
      error: "address",
    },
    {
      label: t("Password"),
      type: "password",
      id: "password",
      name: "password",
      placeholder: t("Enter Your Password"),
      register: "password",
      error: "password",
    },
    {
      label: t("Confirm Password"),
      type: "password",
      id: "confirm_password",
      name: "confirm_password",
      placeholder: t("Enter Your Confirm Password"),
      register: "confirm_password",
      error: "confirm_password",
    },
  ];

  return (
    <>
      {!_AuthApi.getToken() ? (
        <section>
          <div className=" px-4 md:px-20 md:flex md:flex-row flex flex-col gap-5">
            <div className=" md:flex-1 border border-1 border-primary-color p-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-5">
                  <div>
                    <p className="bodoni text-3xl ">{t("Register")}</p>
                  </div>
                  <div className="grid grid-cols-1 md:grid md:grid-cols-2 gap-10">
                    {feilds.map((item) => (
                      <div className="flex flex-col gap-5">
                        <label className="font-medium">{item.label} *</label>
                        <input
                          type={item.type}
                          id={item.id}
                          name={item.name}
                          pattern={item.pattern}
                          placeholder={item.placeholder}
                          {...register(item.register)}
                          className="border border-1 border-secondary-color p-2 w-full"
                        />
                        {errors[item.error]?.message && (
                          <p className="text-[red]">
                            {errors[item.error]?.message}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                  <label className="font-medium">{t("Phone")} *</label>

                  <PhoneInput
                    placeholder={t("Enter Your Phone Number")}
                    value={value}
                    onChange={setValue}
                    {...register("phone")}
                    className="border border-1 border-secondary-color p-2 w-full"
                  />
                  {errors.phone?.message && (
                    <p className="text-[red]">{errors.phone?.message}</p>
                  )}
                  <label htmlFor="dropdown">{t("City")} *</label>
                  <select
                    id="dropdown"
                    value={selectedOption.id}
                    onChange={handleSelectOption}
                    className="border border-1 border-secondary-color p-2 w-full"
                  >
                    <option value="">{t("Select")}</option>
                    {options?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end mt-10">
                  <button
                    className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5 w-40 justify-center font-semibold md:mt-10"
                    type="submit"
                  >
                    {t("Submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Register;
