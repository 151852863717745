import React from "react";

import { Rating } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { _axios } from "interceptor/http-config";
import { useTranslation } from "react-i18next";

const Influencers = () => {
  const params = useParams();
  const { t } = useTranslation("index");

  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    ["influencers", `id-${params.id}`],
    async () => {
      return await _axios
        .get(`/user/influencer/${params.id}/product`)
        .then((res) => res.data?.influencer);
    },
    {}
  );
  const handleView = (id) => {
    navigate(`/product/view/${id}`);
  };
  return (
    <section className=" flex flex-col gap-10">
      {isLoading && (
        <div className=" flex justify-center w-screen">
          <p className="loader"></p>
        </div>
      )}
      <div className="bg-[#C1CB9052] w-full  flex flex-col items-center gap-7 py-5">
        <h1 className="bodoni text-4xl">{data?.name ?? "null"}</h1>
        <h1 className="bodoni  text-center md:text-xl">
          Find the perfect rose for your garden. Browse our range of stunning
          British roses and buy online today.
        </h1>
      </div>
      <div className="md:mx-40 grid grid-cols-1 md:grid md:grid-cols-3  gap-10 px-5">
        {data?.products?.map((item, index) => (
          <div key={index}>
            <div
              className="border border-1 p-2 bg-white flex flex-col items-center gap-2 md:w-[100%]"
              onClick={() => handleView(item.id)}
            >
              <img src={item.image} alt="" className="h-52 w-full" />
              <p className="text-xl font-medium">{item.name}</p>

              <Rating
                readOnly
                name="half-rating"
                sx={{ color: "#F86338" }}
                value={item.rate ?? "null"}
                precision={0.5}
              />
              <p>{t("Watch")}</p>
              <p className="text-[#F3692E] text-xl">${item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Influencers;
