import React, { useEffect, useState } from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";

const Layout = ({ children, ShowNav, setShowNav }) => {
  const [direction, setdirection] = useState("ltr");
  const [lang, setLang] = useState(localStorage?.getItem("i18nextLng"));
  useEffect(() => {
    const lang = localStorage?.getItem("i18nextLng");
    setLang(lang === "en" ? "en" : "ar");
    setdirection(lang === "en" ? "ltr" : "rtl");
  }, [lang]);

  return (
    <div dir={direction}>
      <Header
        setLang={setLang}
        lang={lang}
        direction={direction}
        ShowNav={ShowNav}
        setShowNav={setShowNav}
      />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
