import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInfluencer } from "hooks/influencer/useInfluencer";
import { StaggeredGrid, StaggeredGridItem } from "react-staggered-grid";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Influencers = () => {
  const { data, isLoading } = useInfluencer();
  const { t } = useTranslation("index");

  const navigate = useNavigate();
  const handleView = (id) => {
    navigate(`name-famous/${id}`);
  };
  const [shouldRenderGrid, setShouldRenderGrid] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setShouldRenderGrid(true);
    }, 100);

    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      <div className=" bg-[#C1CB9052] w-full  flex flex-col items-center gap-7 py-5 my-10">
        <h1 className="text-3xl text-center bodoni md:text-4xl">
          {t("Influencers Beautiful Creations")}
        </h1>
        <h1 className="text-center bodoni text-md md:text-xl">
          {t("It’s also a journey with our partners")}
        </h1>
      </div>
      {isLoading && (
        <div className="flex justify-center w-screen ">
          <p className="loader"></p>
        </div>
      )}
      <section className="hidden mx-20 md:block">
        {shouldRenderGrid && (
          <StaggeredGrid
            columnWidth={500}
            columns={0}
            alignment={1}
            horizontalGap={10}
            fitHorizontalGap={true}
            verticalGap={10}
          >
            {data?.influencers?.map((item, index) => (
              <StaggeredGridItem key={index} index={index}>
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ width: "100%" }}
                  title={item.name}
                  onClick={() => handleView(item.id)}
                />
              </StaggeredGridItem>
            ))}
          </StaggeredGrid>
        )}
      </section>
      <section className="block md:hidden">
        <div className="grid grid-cols-2 gap-3 px-5 md:grid md:grid-cols-2 lg:grid lg:grid-cols-3 lg:px-10 ">
          {data?.influencers?.map((item, index) => (
            <div key={index}>
              <div
                className="border border-1 p-2 bg-white flex flex-col items-center w-[100%] rounded"
                onClick={() => handleView(item.id)}
              >
                <img src={item.image} alt="" className="w-full h-52 " />
                <p className="text-xl font-medium">{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Influencers;
