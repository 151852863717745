import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import arro from "../../asset/images/layout/baseline_menu_black_24dp.png";
import cart from "../../asset/images/Header/Cart.png";
import logoRes from "../../asset/images/Header/garden.jpg";
import { _AuthApi } from "api/auth";
import account from "../../asset/icons/header/account.svg";
import facebook from "../../asset/images/Home/index/facbook-hero.png";
import instgram from "../../asset/images/Home/index/instgram-hero.png";
import whatsapp from "../../asset/images/Home/index/whatsapp-hero.png";
const HeaderResponsive = ({ setLang, direction, lang }) => {
  const [ShowNav, setShowNav] = useState(false);

  const [land, setland] = useState("");
  const [showsliderlanguage, setshowsliderlanguage] = useState(false);

  const { t, i18n } = useTranslation("navbar");
  const hanldeOpen = () => {
    setShowNav(!ShowNav);
  };

  useEffect(() => {
    if (direction === "rtl") {
      setLang("ar");
      setshowsliderlanguage(true);
    } else {
      setLang("en");
      setshowsliderlanguage(false);
    }
    i18n.changeLanguage(land);
    setLang(land);
    i18n.changeLanguage(land);
  }, [direction, land, i18n, setLang]);
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  const navs = [
    { name: t("All Roses"), navlink: "/all-roses" },
    { name: t("Influencers beautiful creations"), navlink: "/Influencers" },
    { name: t("Occasions"), navlink: "/occasions" },
    { name: t("Gifts"), navlink: "/gifts " },
    { name: t("Subscriptions"), navlink: "/subscription" },
  ];

  return (
    <div>
      <div className="z-30 flex items-center justify-between p-5 sm:hidden">
        <div className="w-10 h-10">
          <div
            className=" lg:hidden md:hidden sm:block xs:block"
            onClick={() => {
              setShowNav(!ShowNav);
            }}
          >
            <img src={arro} alt="" />
          </div>
        </div>
        <div>
          <NavLink to="/">
            <img src={logoRes} alt="" className="w-32" />
          </NavLink>
        </div>
        <div className="flex gap-5 ">
          {ShowNav ? (
            <div></div>
          ) : (
            <div className="flex gap-5">
              <div className="flex items-center ">
                <NavLink to="/cart">
                  <img src={cart} alt="" />
                </NavLink>
              </div>
              <button
                onClick={() => {
                  showsliderlanguage ? setland("en") : setland("ar");
                }}
              >
                {t("AED")}
              </button>
            </div>
          )}
        </div>
      </div>
      {ShowNav && (
        <>
          <div
            className={
              direction === "ltr"
                ? "fixed w-[70vw] h-full bg-white  top-[0px] bottom-[0%] left-0  text-xl z-30 "
                : "fixed w-[70vw] h-full bg-white  top-[0px] bottom-[0%] right-0  text-xl z-30  "
            }
          >
            <p
              onClick={hanldeOpen}
              className="bg-[#C1CB90] text-xl  text-black p-2   "
            >
              X {t("Close")}
            </p>
            <div className="flex flex-col gap-10 px-3 py-8 text-sm">
              {navs?.map((nav) => (
                <NavLink to={nav.navlink}>{nav.name}</NavLink>
              ))}

              {!_AuthApi.getToken() ? (
                <NavLink to="/account">
                  <div className="flex gap-3">
                    <img src={account} alt="" />
                    Account
                  </div>
                </NavLink>
              ) : (
                <NavLink to="/profile">
                  <div className="flex gap-3">
                    <img src={account} alt="" />
                    Profile
                  </div>
                </NavLink>
              )}
            </div>
            <div className="flex justify-around mt-10 sm:hidden">
              <a href="/">
                <img src={facebook} alt="" />
              </a>
              <a href="/">
                <img src={instgram} alt="" />
              </a>
              <a href="/">
                <img src={whatsapp} alt="" />
              </a>
            </div>
          </div>
        </>
      )}
      <div
        onClick={hanldeOpen}
        className={`bg-transparnet text-transparnet w-full ${
          ShowNav ? "h-screen" : "h-full"
        }`}
      ></div>
    </div>
  );
};

export default HeaderResponsive;
