import React from "react";
import mail from "../../asset/icons/footer/footerMail.svg";
import call from "../../asset/icons/footer/footerCall.svg";
import footerLogo from "../../asset/images/Header/garden.jpg";
import footerLogoRes from "../../asset/images/Header/garden.jpg";
import visa from "../../asset/icons/footer/footerVisa.svg";
import masterCard from "../../asset/icons/footer/footerMasterCard.svg";
import paypal from "../../asset/icons/footer/footerPayPal.svg";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation("index");

  const footerItem = [
    {
      head: t("Pages"),
      body1: <a href="/all-roses">{t("All Roses")}</a>,
      body2: <a href="/influencers">{t("Influencers beautiful creations")}</a>,
      body3: <a href="/occasions">{t("Occasions")}</a>,
      body4: <a href="/gifts">{t("Gifts")}</a>,
      body5: <a href="/subscription">{t("Subscriptions")}</a>,
    },
    {
      head: t("INFORMATION"),
      body1: <a href="/shipping">{t("Shipping & Delivery")}</a>,
      body2: <a href="/terms">{t("Terms & Condition")}</a>,
      body3: <a href="/privacy">{t("Privacy Policy")}</a>,
    },
  ];
  const footerResponsive = [
    {
      body1: <a href="/shipping">{t("Shipping & Delivery")}</a>,
      body2: <a href="/terms">{t("Terms & Condition")}</a>,
      body3: <a href="/privacy">{t("Privacy Policy")}</a>,
      body4: <a href="/help-support">{t("Help & Support")}</a>,
    },
  ];
  return (
    <div>
      <div className="hidden md:block">
        <div className="flex bg-[#DADDE252] h-32  gap-40 pl-20">
          <div className="flex flex-col justify-center gap-2 px-5 ">
            <h3 className=" md:hidden lg:block lg:text-[1.7rem] xl:text-[2.0rem] w-full flex ">
              {t(
                "Join us on this journey through the captivating world of flowers."
              )}
            </h3>
          </div>
          <div className="flex justify-center gap-10 ">
            <div className="flex items-center gap-5 px-5">
              <img src={mail} alt="" className="w-5" />
              <a href="mailto:flowers@gardenofedenshop.com">
                flowers@gardenofedenshop.com
              </a>
            </div>
            <div className="flex items-center gap-5 px-5">
              <img src={call} alt="" className="w-5" />
              <a href="tel:+971507726271">+971507726271</a>
            </div>
          </div>
        </div>
        <div className="flex justify-around gap-20 max-w-[1400px] py-10 ">
          <div className="">
            <img src={footerLogo} alt="" className="w-80" />
          </div>
          {footerItem.map((item, index) => (
            <div className="flex flex-col leading-10 " key={index}>
              <h6 className="text-xl font-bold">{item.head}</h6>
              {Object.values(item)
                .slice(1)
                .map((bodyItem, bodyIndex) => (
                  <div key={bodyIndex}>
                    <a href={`#${bodyItem}`}>{bodyItem}</a>
                  </div>
                ))}
            </div>
          ))}
        </div>
        <div className="flex bg-[#FFD6AF4A] py-2 payment">
          <div className="flex items-center justify-center flex-1 ">
            <h1 className="text-sm">
              {t("Copyright Flower. All rights reserved")}
            </h1>
          </div>
          <div className="flex items-center justify-center flex-1 gap-10 ">
            <p>{t("PAYMENT METHODS")} :</p>
            <img src={visa} alt="" />
            <img src={masterCard} alt="" />
            <img src={paypal} alt="" />
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div>
          <div className="flex flex-col items-center justify-center gap-8">
            <div className="bg-[#DADDE252] w-full flex justify-center p-10">
              <h1 className="text-3xl ">{t("WE HAVE YOUR BACK")}</h1>
            </div>
            <img src={footerLogoRes} alt="" className="w-80" />
            <div className="flex items-center gap-5">
              <img src={mail} alt="" className="w-8" />
              <a href="mailto:flowers@gardenofedenshop.com">
                flowers@gardenofedenshop.com
              </a>
            </div>
            <div className="flex items-center gap-5">
              <img src={call} alt="" className="w-8" />
              <a href="tel:+971507726271">+971507726271</a>
            </div>
            {footerResponsive.map((item, index) => (
              <div className="flex flex-col items-center gap-6 " key={index}>
                {Object.values(item)
                  .slice(1)
                  .map((bodyItem, bodyIndex) => (
                    <div key={bodyIndex} className="font-semibold ">
                      <a href={`#${bodyItem}`}>{bodyItem}</a>
                    </div>
                  ))}
              </div>
            ))}
            <p className="text-3xl payment">{t("PAYMENT METHODS")}:</p>
            <div className="flex gap-10 ">
              <img src={visa} alt="" />
              <img src={masterCard} alt="" />
              <img src={paypal} alt="" />
            </div>
          </div>
        </div>
        <div className="flex-1 flex justify-center items-center bg-[#FFD6AF4A] py-2 payment my-10">
          <h1 className="text-sm">
            {t("Copyright Flower. All rights reserved")}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
