import { useQuery } from "react-query";
import { _Product } from "api/home/product";

export const useProduct = (id) => {
  const { data, isLoading } = useQuery(
    ["products"],
    () => _Product.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
