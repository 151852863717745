import { Rating } from "@mui/material";
import { useOrder } from "hooks/profile/useOrder";
import { _axios } from "interceptor/http-config";
import React, { useState } from "react";
import { categoryStore } from "store/categoryStroe";

const Review = () => {
  const { data } = useOrder();
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState(0);
  const [images, setImages] = useState([]);
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const [editedID, setEditedID] = categoryStore((state) => [
    state.editedID,
    state.setEditedID,
  ]);

  const handleImageChange = (event) => {
    const fileList = event.target.files;
    const imageArray = Array.from(fileList);
    setImages(imageArray);
  };

  const submitReview = async () => {
    try {
      const formData = new FormData();
      formData.append("description", description);
      formData.append("rate", rate);
      for (let i = 0; i < images.length; i++) {
        formData.append(`images[${i}]`, images[i]);
      }

      await _axios.post(`/user/review/${data.id}}`, formData);
    } catch (error) {
      alert("error");
    }
  };
  const handleRatingChange = (event, value) => {
    setRate(value);
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50  "
      style={{ zIndex: 9999 }}
    >
      <div className="bg-white flex flex-col rounded-[20px] justify-center p-4 w-[600px] h-[400px] m-5 md:m-0">
        <form>
          <button onClick={() => setEditedID(false)}>X</button>
          <div className="flex flex-col items-center justify-center gap-10 ">
            <Rating
              name="rate"
              precision={1}
              value={rate}
              onChange={handleRatingChange}
            />
            <input
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter description"
              name="description"
              className="border border-1 border-secondary-color p-2 w-full"
            />

            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              multiple
              name="images"
              className="border border-1 border-secondary-color p-2 w-full"
            />
            <button
              className="flex justify-center text-white bg-[#C1CB90] rounded p-3 gap-5  w-1/2"
              onClick={submitReview}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Review;
