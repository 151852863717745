import React from "react";
import { useTranslation } from "react-i18next";
import { useProfile } from "hooks/profile/useProfile";
import { useOrder } from "hooks/profile/useOrder";
import { _AuthApi } from "api/auth";
import { useWishlist } from "hooks/profile/useWishlist";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { categoryStore } from "store/categoryStroe";
import Review from "pages/review/Review";
const Profile = () => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useProfile();
  const { data: order, isLoading: isLoading1 } = useOrder();
  const { data: wishlist, isLoading: isLoading2 } = useWishlist();
  const navigate = useNavigate();
  const handleLogout = () => {
    _AuthApi.logout().then(() => navigate("/"));
  };
  const handleView = (id) => {
    navigate(`/product/view/${id}`);
  };

  const details = [
    { name: data?.profile?.first_name, value: data?.profile?.last_name },
    { name: t("Email"), value: data?.profile?.email },
    { name: t("Phone"), value: data?.profile?.phone },
    { name: t("Address"), value: data?.profile?.address },
    { name: t("City"), value: data?.profile?.city },
  ];
  // const handleViewReview = (id) => {
  //   navigate(`review/${id}`);
  // };
    const [editedID, setEditedID] = categoryStore((state) => [
      state.editedID,
      state.setEditedID,
    ]);
    const handleEdit = (id) => {
      setEditedID(id);
    };
       
  return (
    <>
      {isLoading && (
        <div className=" flex justify-center w-screen h-screen items-center">
          <p className="loader"></p>
        </div>
      )}
      {editedID && <Review id={editedID} />}

      <section className="flex mx-5  md:mx-32 flex-col">
        <div className="flex  max-w-[200px] md:max-w-[550px] ">
          <div className="grid grid-cols-2 gap-5">
            {details?.map((item, index) => (
              <>
                <p
                  style={{ fontWeight: index === 0 ? 800 : 100 }}
                  className={`${index === 0 ? "bodoni text-3xl" : "text-xl"}`}
                >
                  {item.name}
                </p>
                <p
                  style={{ fontWeight: index === 0 ? 800 : 100 }}
                  className={`${index === 0 ? "bodoni text-3xl" : "text-xl"}`}
                >
                  {item.value}
                </p>
              </>
            ))}
          </div>
          <div>
            <button
              className="hidden md:flex font-medium items-center text-white bg-[#C1CB90]  rounded-[10px] p-2  w-40  justify-center"
              onClick={handleLogout}
            >
              {t("Logout")}
            </button>
          </div>
        </div>
        <button
          className="block md:hidden flex font-medium items-center text-white bg-[#C1CB90]  rounded-[10px] p-2  w-40 mt-10 justify-center"
          onClick={handleLogout}
        >
          {t("Logout")}
        </button>
        <section className="flex flex-col  ">
          <div>
            <h1 style={{ fontWeight: 800 }} className="bodoni text-3xl">
              {t("My Order")}
            </h1>
          </div>
          <div className="flex flex-col gap-2 my-10">
            {order?.orders?.map((item, index) => (
              <div
                className=" grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-5 border border-1 border-primary-color w-1/1 md:w-1/2 p-5 gap-10 md:gap-0  rounded-[15px] bodoni font-medium items-center pl-10"
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <p>{item.order_id}</p>
                <p>{item.quantity}</p>
                <p>${item.total_price}</p>
                <p className="text-secondary-color underline text-2xl">
                  {item.status}
                </p>
                {item.status === "done" && (
                  <button
                    className="flex font-medium items-center text-white bg-[#C1CB90]  rounded-[10px] p-2  w-1/1  justify-center"
                    onClick={() => handleEdit(item.id)}
                  >
                    {t("Write Review")}
                  </button>
                )}
              </div>
            ))}
          </div>
        </section>
        <div className="flex flex-col gap-20">
          <div>
            <h1 style={{ fontWeight: 800 }} className="bodoni text-3xl">
              {t("Wishlist")}
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid md:grid-cols-2 lg:grid lg:grid-cols-3  gap-5 px-5 lg:px-10 ">
            {wishlist?.favorite_products?.map((item, index) => (
              <div key={index}>
                <div
                  className="border border-1 p-2 bg-white flex flex-col items-center gap-2 md:w-[100%]"
                  onClick={() => handleView(item.id)}
                >
                  <img src={item.image} alt="" className="h-52 w-full " />
                  <p className="text-xl font-medium">{item.name}</p>
                  <Rating
                    readOnly
                    name="half-rating"
                    sx={{ color: "#F86338" }}
                    defaultValue={item.rate ?? "null"}
                    precision={1}
                  />
                  <p className="text-[#F3692E]">${item.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
