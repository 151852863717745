import { _axios } from "interceptor/http-config";

export const _AuthApi = {
  login: (data) => {
    return _axios
      .post("/user/login", data)
      .then((res) => _AuthApi.storeToken(res?.data?.access_token));
  },
  logout: (data, navigate) => {
    return _axios
      .get("/user/logout", data)
      .then((res) => _AuthApi.destroyToken())
      .then(() => navigate("/"));
  },
  register: (data, setLoading, navigate, enqueueSnackbar) =>
    _axios.post("/user/register", data).then((res) => {
      setLoading(true);
      // navigate(-1);
      enqueueSnackbar("Please Verify Your Email");
    }),

  storeToken: (access_token) => {
    localStorage.setItem("access_token", access_token);
  },

  getToken: () => localStorage.getItem("access_token"),

  destroyToken: () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  },

  // changePass: (data) => {
  //   return _axios
  //     .post("/admin/password/update", data)
  //     .then((res) => alert("Password Changed"));
  // },
  resetPass: (data) => {
    return _axios
      .post("/user/resetPassword", data)
      .then((res) => console.log("done"));
  },
  verifyCode: (data) => {
    return _axios
      .post("/user/checkCode", data)
      .then((res) => console.log("done"));
  },
  passEdit: (data) => {
    return _axios
      .post("/user/editPassword", data)
      .then((res) => console.log("done"));
  },
};
