import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
let schema = yup.object().shape({
  email: yup
    .string()
    .email("This must be a valid email")
    .required("Email is required"),
});
const ResetPassword = () => {
  const { t } = useTranslation("index");

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (e) => {
    _AuthApi
      .resetPass({ email })
      .then((res) =>
        navigate(`/reset-password/check-code/${email}`, { replace: true })
      )
      .catch((err) => console.log(err));
  };

  return (
    <>
      <section className="flex flex-col items-center ">
        <div className="  md:px-20 md:flex md:flex-col flex flex-col gap-5 w-full px-5 md:w-1/2">
          <div className=" md:flex-1 border border-1 border-primary-color p-5">
            <div className="flex  justify-center ">
              <p className="text-2xl font-medium">{t("Reset Your Password")}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-5">
                <label className="font-medium">{t("Email")}</label>
              </div>
              <input
                type="email"
                sx={{ width: "100%" }}
                placeholder="Enter your email"
                {...register("email", { validate: true })}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className=" border border-1 border-secondary-color p-2 w-full"
              />
              {errors.email && (
                <span className="field_level_error" style={{ color: "red" }}>
                  {errors.email.message}
                </span>
              )}
              <div className="mt-10 w-[200] flex justify-center">
                <button
                  className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5  justify-center font-semibold"
                  type="submit"
                >
                  Send Your Email
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
