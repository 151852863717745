import React from "react";
import SideBar from "./SideBar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideBarLayout = ({ children, id }) => {
  const { t } = useTranslation("index");

  const params = useParams();
  const isSubscriptionPage = window.location.pathname === "/subscription";
  const isAllRoses = window.location.pathname === "/all-roses";
  const isInfluancer = window.location.pathname === "/influencers-view";
  const isInfluancerID =
    window.location.pathname === `/influencers-view/view/${params.id}`;
  const isOccasions = window.location.pathname === "/occasions";
  const isGifts = window.location.pathname === "/gifts";

  let title = "";
  let content = "";
  if (isAllRoses) {
    title = t("All Roses");
    content = t(
      "Make every moment unforgettable with the right choice of flowers."
    );
  }
  if (isInfluancer) {
    title = t("Influencers Beautiful Creations");
    content = t("It’s also a journey with our partners");
  }
  if (isInfluancerID) {
    title = t("Influencers Beautiful Creations");
    content = t("It’s also a journey with our partners");
  }
  if (isOccasions) {
    title = t("Occasions");
    content = t("Find your special flower gifts for your special occasion");
  }
  if (isGifts) {
    title = t("Gifts");
    content = t(
      "Make every moment unforgettable with the right choice of flowers."
    );
  }
  if (isSubscriptionPage) {
    title = t("Subscriptions");
    content = t("Once you're into flowers you're never out!");
  }

  return (
    <div className=" my-10">
      <div className=" bg-[#C1CB9052] w-full  flex flex-col items-center gap-7 py-5 ">
        <h1 className="bodoni text-4xl">{title}</h1>
        <h1 className="bodoni  text-center md:text-xl">{content}</h1>
      </div>
      <div className="flex flex-col md:flex md:flex-row  py-10">
        <SideBar />
        <div className=" w-full">{children}</div>
      </div>
    </div>
  );
};

export default SideBarLayout;
