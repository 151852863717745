import { _AuthApi } from "api/auth";
import { _axios } from "../../interceptor/http-config";

const access_token = _AuthApi.getToken();
export const _Profile = {
  index: async () => {
    return _axios
      .get(`/user/profile`, {
        headers: {
          Authorization: access_token ? `Bearer ${access_token}` : "",
        },
      })
      .then((res) => res.data);
  },
  order: async () => {
    return _axios
      .get(`/user/order`, {
        headers: {
          Authorization: access_token ? `Bearer ${access_token}` : "",
        },
      })
      .then((res) => res.data);
  },
  wishlist: async () => {
    return _axios
      .get(`/user/favorite`, {
        headers: {
          Authorization: access_token ? `Bearer ${access_token}` : "",
        },
      })
      .then((res) => res.data);
  },
};
