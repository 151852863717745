import { useQuery } from "react-query";
import { _Profile } from "api/profile/profile";

export const useProfile = (id) => {
  const { data, isLoading } = useQuery(
    ["profile"],
    () => _Profile.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
