import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import "./output.css";
import Layout from "./layout/Layout";
import Errors from "./pages/404/Errors";
import AllRoses from "./pages/All_Roses/AllRoses";
import SideBarLayout from "./pages/sideBar/SideBarLayout";
import Influencers from "./pages/Influencers/Influencers";
import Wishlist from "./pages/All_Roses/Wishlist";
import Account from "./pages/account/Account";
import InfluencersView from "./pages/Influencers/InfluencersView";
import { useSnackbar } from "notistack";
import { HttpResponseInterceptor } from "./interceptor/http-response.interceptor";
import { HttpRequestInterceptor } from "./interceptor/http-request.interceptor";
import Register from "pages/account/Register";
import ProductsView from "pages/products/ProductsView";
import NameFamous from "pages/Name_Famous/NameFamous";
import Occasions from "pages/Occasions/Occasions";
import Gifts from "pages/Gifts/Gifts";
import Subscription from "pages/Subscription/Subscription";
import Profile from "pages/profile/Profile";
import ShouldBeLogged from "middlewares/ShouldBeLogged";
import ResetPassword from "pages/ResetPassword";
import VerificationCodeForm from "pages/VerificationCodeForm";
import PasswordEditForm from "pages/PasswordEditForm";
import ShouldNotBeLogged from "middlewares/ShouldNotBeLogged";
import { v4 as uuidv4 } from "uuid";
import Cart from "pages/cart/Cart";
import CheckOut from "pages/cart/CheckOut";
import Review from "pages/review/Review";
function App() {
  <Helmet>
    <title>Garden</title>
    <meta name="description" content="Garden Of Eden" />
  </Helmet>;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    HttpRequestInterceptor();
    HttpResponseInterceptor(navigate, enqueueSnackbar);
  }, []);
  const [direction, setdirection] = useState("ltr");
  const [lang, setLang] = useState("en");

  useEffect(() => {
    const lang = localStorage?.getItem("i18nextLng");
    setLang(lang === "en" ? "en" : "ar");
    setdirection(lang === "en" ? "ltr" : "rtl");
  }, [lang]);
  const [session_id, setSessionId] = useState("");

  useEffect(() => {
    const storedSessionId = localStorage.getItem("session_id");

    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      localStorage.setItem("session_id", newSessionId);
    }
  }, []);
  return (
    <div className="App" dir={direction}>
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route
            path="/profile"
            element={
              <ShouldBeLogged>
                <Profile />
              </ShouldBeLogged>
            }
          />
          <Route
            path="/account"
            element={
              <ShouldNotBeLogged>
                <Account />
              </ShouldNotBeLogged>
            }
          />

          <Route
            element={
              <SideBarLayout>
                <Outlet />
              </SideBarLayout>
            }
          >
            <Route path="/all-roses" element={<AllRoses />} />
            <Route path="/occasions" element={<Occasions />} />
            <Route path="/gifts" element={<Gifts />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route
              path="/influencers-view/view/:id"
              element={<InfluencersView />}
            />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile/review/:id" element={<Review />} />
          <Route path="/cart/check-out" element={<CheckOut />} />
          <Route path="/product/view/:id" element={<ProductsView />} />
          <Route path="/influencers" element={<Influencers />} />
          <Route path="/Influencers/name-famous/:id" element={<NameFamous />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password/check-code/:email"
            element={<VerificationCodeForm />}
          />
          <Route
            path="/reset-password/edit-password/:email/:code"
            element={<PasswordEditForm />}
          />
          <Route path="/" element={<Navigate to="/reset-password" />} />
        </Route>
        <Route exact path="*" element={<Errors />} />
      </Routes>
    </div>
  );
}

export default App;
