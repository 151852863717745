import { useQuery } from "react-query";
import { _GetCategory } from "api/getCategory/getCategory";

export const useGetCategory = (id) => {
  const { data, isLoading } = useQuery(
    ["category"],
    () => _GetCategory.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
