import { useQuery } from "react-query";
import { _LinksApi } from "api/links/links";

export const useLinks = (id) => {
  const { data, isLoading } = useQuery(
    ["links"],
    () => _LinksApi.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
