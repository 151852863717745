import React from "react";
import { Rating } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { _axios } from "interceptor/http-config";
import { useTranslation } from "react-i18next";

const InfluencersView = () => {
  const params = useParams();
  const { t } = useTranslation("index");

  const { data } = useQuery(
    ["influencers", `id-${params.id}`],
    async () => {
      return await _axios
        .get(`/user/influencer/${params.id}/product`)
        .then((res) => res.data?.influencer);
    },
    {}
  );
  const navigate = useNavigate();
  const handleView = (id) => {
    navigate(`/product/view/${id}`);
  };
  return (
    <div>
      <div className="grid grid-cols-2 md:grid md:grid-cols-3  gap-4 px-5">
        {data?.products?.map((item, index) => (
          <div key={index}>
            <div
              className="border border-1 p-2 bg-white flex flex-col items-center"
              onClick={() => handleView(item.id)}
            >
              <img src={item.image} alt="" className="h-52 w-full" />
              <p>{item.name}</p>
              <Rating
                readOnly
                name="half-rating"
                sx={{ color: "#F86338" }}
                value={item.rate ?? "null"}
                precision={0.5}
                // onChange={(event, newValue) => {
                //   setValue(newValue);
                // }}
              />
              <p>{t("Watch")}</p>
              <p>${item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfluencersView;
