import React, { useState } from "react";

import { Rating } from "@mui/material";
import { FaHeart, FaRegHeart, FaCartPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { _axios } from "interceptor/http-config";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { _AuthApi } from "api/auth";
import { useSnackbar } from "notistack";
const ProductsView = () => {
  const { t } = useTranslation("index");

  const { enqueueSnackbar } = useSnackbar();

  const [data1, setData1] = useState();
  const handleClick = (index) => setData1(index);

  const [isWishlist, setIsWishlist] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [note, setNote] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const openPopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    const maxQuantity = data.quantity;
    if (quantity < maxQuantity) {
      setQuantity(quantity + 1);
    }
  };
  const params = useParams();

  const { data, isLoading } = useQuery(
    ["influencers", `id-${params.id}`],
    async () => {
      return await _axios
        .get(`/user/product/${params.id}`)
        .then((res) => res.data?.products);
    },
    {}
  );
  let totalPrice = 0;

  if (data && data.price) {
    const pricePerProduct = data.price;
    totalPrice = quantity * pricePerProduct;
  }
  const handleToggleWishlist = async (event) => {
    event.stopPropagation();
    setIsWishlist(!isWishlist);
    try {
      await _axios.get(`/user/favoriteToggle/${params.id}`).then(() =>
        enqueueSnackbar("Done", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
    } catch (error) {
      alert(error);
    }
    const wishlistItems =
      JSON.parse(localStorage.getItem("wishlistItems")) || [];
    localStorage.setItem("wishlistItems", JSON.stringify(wishlistItems));
  };
  const handleAddToCart = async () => {
    try {
      const formData = new FormData();
      note && formData.append("note", note);
      formData.append("quantity", quantity);
      const session_id = localStorage.getItem("session_id");
      formData.append("session_id", session_id);
      await _axios.post(`/user/cart/addProduct/${params.id}`, formData);

      closePopup();
    } catch (error) {
      alert("Error adding product to cart:", error);
    }
  };
  console.log("data", data?.is_favorite);
  return (
    <>
      {isLoading && (
        <div className=" flex justify-center w-screen">
          <p className="loader"></p>
        </div>
      )}
      {!!data && (
        <section>
          <div className="flex flex-col lg:flex lg:flex-row  px-10 sm:gap-10  ">
            <div className="flex-[0.4] lg:flex lg:flex-col lg:items-center">
              <img
                src={!data1 ? data.gallery[0]?.url : data.gallery[data1].url}
                height="550"
                width="600"
                alt=""
                style={{ borderRadius: "10px" }}
              />
              <div className="flex justify-center pt-10 md:w-[600px]">
                {data?.gallery?.map((item, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                      }}
                      className="flex px-2 items-center"
                      key={index}
                    >
                      <img
                        src={item.url}
                        onClick={() => handleClick(index)}
                        className="h-[100px] w-[200px] md:h-[200px] "
                        alt="Garden"
                        style={{
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="flex-[0.5] mt-20 md:mt-0 ">
              <div className="flex flex-col  h-full gap-6">
                <p className="bodoni text-4xl ">{data?.name ?? null}</p>
                <Rating
                  readOnly
                  name="half-rating"
                  sx={{ color: "#F86338" }}
                  defaultValue={data.rate ?? "null"}
                  precision={0.5}
                />
                <p className="text-secondary-color text-4xl">{data.price} $</p>
                <p className="text-3xl font-bold">{t("Details Products")}</p>
                <p>{data.description}</p>
                <div className="flex justify-between">
                  <div>
                    <p className="font-bold">{t("Quantity")}</p>
                  </div>
                  <div className="flex gap-7">
                    <button onClick={decreaseQuantity} disabled={quantity <= 1}>
                      -
                    </button>
                    <span>{quantity}</span>
                    <button onClick={increaseQuantity}>+</button>
                  </div>
                  <div>
                    <button className="text-[#FFBD80]" onClick={openPopup}>
                      {t("Add Note")}
                    </button>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">{t("Subtotal")}</p>
                  <p>{totalPrice}</p>
                </div>
                <div className="flex gap-10">
                  {!_AuthApi.getToken() ? (
                    <div className="hidden"></div>
                  ) : (
                    <button
                      className="flex items-center border border-1  border-[#FFBD80] text-[#FFBD80] rounded p-3 gap-5"
                      onClick={handleToggleWishlist}
                    >
                      {isWishlist || data?.is_favorite ? (
                        <>
                          {t("Wishlist")}
                          <FaHeart />
                        </>
                      ) : (
                        <>
                          {t("Wishlist")}
                          <FaRegHeart />
                        </>
                      )}
                    </button>
                  )}

                  <button
                    className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5"
                    onClick={handleAddToCart}
                  >
                    {t("Add To Cart")}
                    <FaCartPlus />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <section className="px-10 ">
            <p className="font-bold text-3xl">{t("Reviews")}</p>
            <p className="mt-5">
              {t(
                "Write your review here! We love to hear your feedback always."
              )}
            </p>
            {data.reviews_rates.map((item) => (
              <>
                <div>
                  <div className="flex items-center gap-10">
                    <Stack direction="row" spacing={2}>
                      <Avatar src="/broken-image.jpg" />
                    </Stack>
                    <div className="flex flex-col mt-10 gap-2">
                      <div className="flex gap-2 md:gap-5 mt-10">
                        <p>
                          {item.user_first_name} {item.user_last_name}
                        </p>
                        <Rating
                          readOnly
                          name="half-rating"
                          sx={{ color: "origin.main" }}
                          defaultValue={data.rate ?? "null"}
                        />
                      </div>
                      <div>
                        <p>{new Date(item.created_at).toLocaleString()}</p>
                      </div>
                      {data.gallery?.map((item) => (
                        <div className="flex gap-4">
                          <img
                            src={item.url ?? null}
                            alt=""
                            className="w-12 h-12 rounded bg-red-400"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className=" ml-24">
                  {" "}
                  <p className="text-gray-500 mt-10">{item.description}</p>
                </div>
              </>
            ))}
            {showPopup && (
              <div className="popup-overlay flex ">
                <div className="popup flex flex-col">
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder={t("Enter note")}
                    className="flex w-full"
                  />
                  <div className="flex items-center justify-end text-center mt-5 md:mt-10">
                    <button
                      onClick={closePopup}
                      className="w-1/5 flex text-white bg-[#C1CB90] justify-center p-2 rounded  text-xl "
                    >
                      {t("Close")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </section>
      )}
    </>
  );
};

export default ProductsView;
