import { _axios } from "interceptor/http-config";

export const _Filter = {
  index: async ({ tag, category, color, price, name }) => {
    return _axios
      .get(
        `/user/product?tag=${tag}&color=${color}&category=${category}&price=${price}&name=${name}`
      )
      .then((res) => res.data);
  },
  influencers: async ({ id, category, color, price, name }) => {
    return _axios
      .get(
        `/user/influencer/${id}/product?color=${color}&category=${category}&price=${price}&name=${name}`
      )
      .then((res) => res.data);
  },
};
