import { Typography, Grid } from "@mui/material";

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
let schema = yup.object().shape({
  code: yup
    .string()
    .required("Verification code is required")
    .length(6, "Verification code must be 6 characters long")
    .matches(/^[0-9]+$/, "Verification code must be a number"),
});
const VerificationCodeForm = () => {
  const { t } = useTranslation("index");

  const { email } = useParams();
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (e) => {
    _AuthApi.verifyCode({ email, code }).then((res) =>
      navigate(`/reset-password/edit-password/${email}/${code}`, {
        replace: true,
      }).catch((err) => console.log(err))
    );
  };

  return (
    <>
      <section className="flex flex-col items-center px-5 ">
        <div className="  md:px-20 md:flex md:flex-col flex flex-col gap-5 w-full px-5 md:w-1/2">
          <div className=" md:flex-1 border border-1 border-primary-color p-5">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div className="flex  justify-center ">
                <p className="text-2xl font-medium">{t("Enter Your Code")}</p>
              </div>

              <Typography
                variant="p"
                sx={{
                  mt: "10px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                An email with a verification code has been sent to {email}
              </Typography>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-5">
                <label className="font-medium" htmlFor="code">
                  {t("Code")}
                </label>
              </div>

              <input
                type="text"
                sx={{ width: "100%" }}
                placeholder="Enter your code"
                {...register("code")}
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className=" border border-1 border-secondary-color p-2 w-full"
              />
              {errors.code && (
                <span className="field_level_error" style={{ color: "red" }}>
                  {errors.code.message}
                </span>
              )}
              <div className="mt-10 w-[200] flex justify-center">
                <button
                  className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5  justify-center font-semibold"
                  type="submit"
                >
                  Verify Code
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerificationCodeForm;
