import React from "react";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useFilter } from "hooks/filter/useFilter";

const Occasions = () => {
  const { t } = useTranslation("index");

  const navigate = useNavigate();
  const { data, isLoading } = useFilter();

  const handleView = (id) => {
    navigate(`/product/view/${id}`);
  };
  return (
    <div>
      {isLoading && (
        <div className="flex justify-center w-screen ">
          <p className="loader"></p>
        </div>
      )}
      <div className="grid grid-cols-2 gap-5 px-5 md:grid md:grid-cols-2 lg:grid lg:grid-cols-3 lg:px-10">
        {data?.products?.map((item, index) => (
          <div key={index}>
            <div
              className="border border-1 p-2 bg-white flex flex-col items-center gap-2 md:w-[100%]"
              onClick={() => handleView(item.id)}
            >
              <img src={item.image} alt="" className="w-full h-52 " />
              <p className="text-xl font-medium">{item.name}</p>
              <Rating
            
                readOnly
                name="half-rating"
                sx={{ color: "#F86338" }}
                defaultValue={item.rate ?? "null"}
                precision={1}
              />
              <p>{t("Watch")}</p>
              <p className="text-[#F3692E]">${item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Occasions;
