import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { _axios } from "interceptor/http-config";
import { useCart } from "hooks/cart/useCart";
import { _Cart } from "api/cart/cart";
import { _AuthApi } from "api/auth";
let schema = yup.object().shape({
  first_name: yup.string().required("Fisrt Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup.string().required("phone is required"),
  email: yup.string().required("Email is required"),
  address: yup.string().required("Address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  note: yup.string().required("Note is required"),
});
const CheckOut = () => {
  const { t } = useTranslation("index");

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { mutate } = useMutation((data) => createPost(data));
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [value, setValue] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [radioCard, setRadioCard] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await _axios.get("/user/country", {
          headers: ["en", "ar"].includes(localStorage.getItem("i18nextLng")), // Replace "en" with the desired language value
        });

        const data = response.data.countries;
        setOptions(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const handleSelectOption = (event) => {
    const selectedId = Number(event.target.value);
    const selectedLabel = event.target.options[event.target.selectedIndex].text;

    setSelectedOption({ id: selectedId, name: selectedLabel });
  };

  async function createPost(data) {
    const response = await axios;
    _Cart
      .post(data, setLoading, navigate, enqueueSnackbar)
      .finally(() => setLoading(false));
    setMessage(response.data);
  }

  const onSubmit = async (input) => {
    const formData = new FormData();

    formData.append("first_name", input.first_name);
    formData.append("last_name", input.last_name);

    formData.append("email", input.email);
    formData.append("city", input.city);
    formData.append("state", input.state);
    formData.append("street", input.address);
    formData.append("phone", value);
    formData.append("country_id", selectedOption.id);
    formData.append("note", input.note);
    formData.append("cart_id", data?.cart?.id);
    mutate(formData);
  };
  const feilds = [
    {
      label: t("First Name"),
      type: "text",
      id: "first_name",
      name: "first_name",
      placeholder: t("Enter Your First Name"),
      register: "first_name",
      error: "first_name",
    },
    {
      label: t("Last Name"),
      type: "text",
      id: "last_name",
      name: "last_name",
      placeholder: t("Enter Your Last Name"),
      register: "last_name",
      error: "last_name",
    },

    {
      label: t("Email"),
      type: "email",
      id: "email",
      name: "email",
      placeholder: t("Enter Your Email"),
      register: "email",
      error: "email",
    },
    {
      label: t("Address"),
      type: "text",
      id: "address",
      name: "address",
      placeholder: t("Enter Your Address"),
      register: "address",
      error: "address",
    },
    {
      label: t("City"),
      type: "text",
      id: "city",
      name: "city",
      placeholder: t("Enter Your city"),
      register: "city",
      error: "city",
    },
    {
      label: t("State"),
      type: "text",
      id: "state",
      name: "state",
      placeholder: t("Enter Your state"),
      register: "state",
      error: "state",
    },
  ];
  const { data } = useCart();
  const isCheckOut = window.location.pathname === "/cart/check-out";

  let setp1 = "";
  let setp2 = "";
  let step1Font = "";
  let step2Font = "";
  if (isCheckOut) {
    setp1 = t("All Roses");
    setp2 = t("CheckOut");
    step1Font = "semibold";
    step2Font = "semibold";
  }

  return (
    <>
      <section>
        <div className="flex mb-10 md:mb-20 gap-10  px-4 md:px-20">
          <h1 className={`font-${step1Font} text-2xl`}>1. {setp1}</h1>
          <h1 className={`font-${step2Font} text-2xl`}>2. {setp2}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" px-4 md:px-20 md:flex md:flex-row flex flex-col gap-5">
            <div className=" md:flex-[0.9]  p-5">
              <div className="flex flex-col gap-5">
                <div>
                  <p className="bodoni text-3xl underline">{t("Details")}</p>
                </div>
                <div className="grid grid-cols-1 md:grid md:grid-cols-2 gap-10">
                  {feilds.map((item) => (
                    <div className="flex flex-col gap-5">
                      <label className="font-medium">{item.label} *</label>
                      <input
                        type={item.type}
                        id={item.id}
                        name={item.name}
                        pattern={item.pattern}
                        placeholder={item.placeholder}
                        {...register(item.register)}
                        className="border border-1 border-secondary-color p-2 w-full"
                      />
                      {errors[item.error]?.message && (
                        <p className="text-[red]">
                          {errors[item.error]?.message}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
                <label className="font-medium">{t("Phone")} *</label>

                <PhoneInput
                  placeholder={t("Enter Your Phone Number")}
                  value={value}
                  onChange={setValue}
                  {...register("phone")}
                  className="border border-1 border-secondary-color p-2 w-full"
                />
                {errors.phone?.message && (
                  <p className="text-[red]">{errors.phone?.message}</p>
                )}
                <label htmlFor="dropdown" className="font-medium">
                  {t("Country")} *
                </label>
                <select
                  id="dropdown"
                  value={selectedOption.id}
                  onChange={handleSelectOption}
                  className="border border-1 border-secondary-color p-2 w-full"
                >
                  <option value="">{t("Select")}</option>
                  {options?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <div className="flex flex-col gap-5">
                  <label className="font-medium">
                    {t("Additional information")} *
                  </label>
                  <textarea
                    type="text"
                    id="note"
                    name="note"
                    placeholder="note"
                    {...register("note")}
                    className="border border-1 border-secondary-color p-2 w-full"
                  ></textarea>
                  {errors.note?.message && (
                    <p className="text-[red]">{errors.note.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-[0.3] border border-1 border-primary-color p-5">
              {" "}
              <div>
                <p className="bodoni text-3xl underline ">{t("Your Order")}</p>
              </div>
              <div className="flex flex-col gap-10 mt-20 ">
                <div className=" flex  justify-between items-center font-semibold text-xl border border-t-0 border-l-0 border-r-0 border-2 border-[#FFBD7B]">
                  <label>{t("Product")}</label>
                  <label>{t("Subtotal")}</label>
                </div>
                {data?.cart?.products.map((item) => (
                  <div>
                    <div className=" flex  justify-between items-center border border-t-0 border-l-0 border-r-0 border-2 border-[#FFBD7B]">
                      <p>{item.product_name}</p>
                      <p>{item.product_price}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-10 flex items-center gap-5">
                <input
                  type="radio"
                  className="w-5 h-5"
                  checked={radioCard}
                  onChange={(e) => setRadioCard(e.target.checked)}
                />
                <span className="font-bold text-2xl">
                  {t("Credit Card (Stripe)")}
                </span>
              </div>
              <div className="flex flex-col gap-3 bg-[#DADDE252] p-5 rounded-[20px] mt-10">
                <p>{t("Pay with your credit card via Stripe.")}</p>
                <h1 className="font-bold">{t("Card Number")} *</h1>
                <input
                  type="text"
                  className="border border-1 border-secondary-color p-2 w-full"
                />
                <div className="grid grid-cols-2 gap-5 mt-5">
                  <h1 className="font-bold">{t("Expiry Date")} *</h1>
                  <h1 className="font-bold">{t("Card Code (CVC)")} *</h1>
                  <input
                    type="text"
                    className="border border-1 border-secondary-color p-2 "
                  />
                  <input
                    type="text"
                    className="border border-1 border-secondary-color p-2 "
                  />
                </div>
              </div>
              <div className="flex item-center gap-5 mt-10 my-10 md:my-10">
                <input
                  type="checkbox"
                  className="w-10 h-10"
                  checked={agreeTerms}
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                />
                <span className="md:text-2xl">
                  {t("I have read and agree to the website")}
                  <span className="text-secondary-color">
                    {t("terms and conditions")} *
                  </span>
                </span>
              </div>
              <div className="flex  ">
                {agreeTerms ? (
                  <button
                    className="flex items-center text-white bg-[#C1CB90]  w-full rounded p-3 gap-5 justify-center font-semibold md:mt-10"
                    type="submit"
                  >
                    {t("Order")}
                  </button>
                ) : (
                  <div className="w-full"></div>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default CheckOut;
