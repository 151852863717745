import React, {useState } from "react";


const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);




  return (
    <div>
      <h1>Wishlist</h1>
      {wishlistItems.length === 0 && <p>Your wishlist is empty.</p>}
   
    </div>
  );
};

export default Wishlist;
