import { _Filter } from "api/navBar/filter";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { filterStore } from "store/filterStore";

export const useFilter = () => {
  const [filter, setFilter] = filterStore((state) => [
    state.filter,
    state.setFilter,
  ]);

  const { pathname } = useLocation();
  const params = useParams();

  const { data, isLoading } = useQuery(
    [
      pathname.split("/")[1],
      TagValue(pathname.split("/")[1]),
      filter.category,
      filter.color,
      filter.price,
      filter.name,
    ],
    () => {
      return _Filter[
        pathname.split("/")[1] === "/influencers-view/view" ? "influencers" : "index"
      ]({
        category: filter.category,
        color: filter.color,
        tag: TagValue(pathname.split("/")[1]),
        id: params.id,
        price: filter.price,
        name: filter.name,
      });
    },
    {
      //   enabled: !!pathname.split("/")[1],
    }
  );

  return {
    data,
    isLoading,
    filter,
    setFilter,
  };
};

const TagValue = (tag) => {
  switch (tag) {
    case "all-roses":
      return "all_roses";
      break;
    case "occasions":
      return "occasions";
      break;
    case "gifts":
      return "gifts";
      break;
    case "subscription":
      return "subscription";
      break;

    default:
      return "all_roses";
      break;
  }
};
