import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useMutation } from "react-query";
import { _AuthApi } from "api/auth";
import axios from "axios";
import { useSnackbar } from "notistack";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
let schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),
});
const Account = () => {
  const { t } = useTranslation("index");

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { mutate } = useMutation((data) => createPost(data));
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
 
  async function createPost(data) {
    const response = await axios;
    _AuthApi
      .login(data, setLoading, navigate, enqueueSnackbar)
      .then(() => navigate("/"))
      .finally(() => setLoading(false));
    setMessage(response.data);
  }

  const onSubmit = async (input) => {
    const formData = new FormData();
     const session_id = localStorage.getItem("session_id");
     formData.append("session_id", session_id);
    formData.append("email", input.email);
    formData.append("password", input.password);
    mutate(formData);
  };
  return (
    <>
      <section >
        <div className="flex flex-col gap-5 px-4 md:px-20 md:flex md:flex-row">
          <div className="p-5 border md:flex-1 border-1 border-primary-color">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-3xl bodoni ">{t("Login")}</p>
                </div>
                <div className="flex flex-col gap-10 md:flex md:flex-row">
                  <div className="flex flex-col gap-4">
                    <label className="font-medium">{t("Email")} *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter Your Email"
                      {...register("email")}
                      className="p-2 border border-1 border-secondary-color w-72"
                    />

                    {errors.email && (
                      <span className="text-red-500">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col gap-4">
                    <label className="font-medium">{t("Password")} *</label>
                    <input
                      // required
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Enter Your password"
                      {...register("password")}
                      className="p-2 border border-1 border-secondary-color w-72"
                    />
                    {errors.password && (
                      <span className="text-red-500">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-10">
                <button
                  className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5 w-40 justify-center font-semibold md:mt-10"
                  type="submit"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
            <div className="flex items-center gap-2">
              <p className="mt-5 md:mt-0">{t("Forgotten Your Password?")}</p>
              <NavLink to="/reset-password">
                <p className="pt-5 underline decoration-1 md:pt-0">{t("Click here")}</p>
              </NavLink>
            </div>
          </div>
          <div className="p-5 border md:flex-1 border-1 border-primary-color">
            <div className="flex flex-col gap-5">
              <div>
                <p className="text-3xl bodoni ">{t("Register")}</p>
              </div>
              <div className="flex flex-col ">
                <p>
                  {t(
                    'Don\'t have an account? Click "Register" below and get yourself signed up.'
                  )}
                </p>
                <p>
                  <span className="text-2xl font-bold">.</span>{" "}
                  {t(
                    "Save your delivery information for faster and easier checkout."
                  )}
                </p>
                <p>
                  <span className="text-2xl font-bold">.</span> {t("Easy access to your order history.")}
                </p>
                <p>
                  <span className="text-2xl font-bold">.</span> {t("Keep up to date with your order by checking its status.")}
                </p>
              </div>
            </div>
            <div className="flex justify-end mt-10">
              <NavLink to="/register">
                <button
                  className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5 w-40 justify-center font-semibold"
                  type="submit"
                >
                  {t("Register")}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Account;
