import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
import { Box, Grid, Typography } from "@mui/material";
let schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .min(6, "The confirm password must be of six characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("password")], "your password does not match"),
  createdOn: yup.date().default(function () {
    return new Date();
  }),
});
const PasswordEditForm = () => {
  const { t } = useTranslation("index");

  const { email, code } = useParams();
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const navigate = useNavigate();
  const onSubmit = async (e) => {
    _AuthApi
      .passEdit({
        email,
        code,
        new_password,
        confirm_password,
      })
      .then((res) => navigate("/", { replace: true }))
      .catch((err) => console.log(err));
  };
  return (
    <section className="flex flex-col items-center ">
      <div className="  md:px-20 md:flex md:flex-col flex flex-col gap-5 w-full px-5 md:w-1/2">
        <div className="md:flex-1 border border-1 border-primary-color p-5">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p className="text-2xl font-medium" variant="h5">
              {t("Reset Your Password")}
            </p>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <Typography variant="inputTitle" htmlFor="password">
                {t("New Password")}
              </Typography>
            </Box>
            <input
              type="password"
              sx={{ width: "100%" }}
              placeholder="New Password"
              {...register("password")}
              id="new_password"
              value={new_password}
              onChange={(e) => setNewPassword(e.target.value)}
              className=" border border-1 border-secondary-color p-2 w-full"
            />
            {errors.password && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.password.message}
              </span>
            )}
            <Box sx={{ margin: "20px 0 8px 5px" }}>
              <Typography variant="inputTitle" htmlFor="confirm-password">
                {t("Confirm Password")}
              </Typography>
            </Box>
            <input
              type="password"
              sx={{ width: "100%" }}
              placeholder="Confirm Password"
              {...register("confirm_password")}
              id="confirm_password"
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className=" border border-1 border-secondary-color p-2 w-full"
            />
            {errors.confirm_password && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.confirm_password.message}
              </span>
            )}
            <div className="mt-10 w-[200] flex justify-center">
              <button
                className="flex items-center text-white bg-[#C1CB90]  rounded p-3 gap-5  justify-center font-semibold"
                type="submit"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PasswordEditForm;
