import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import logo from "../../asset/images/Header/garden.jpg";
import cart from "../../asset/images/Header/Cart.png";
import account from "../../asset/icons/header/account.svg";
import HeaderResponsive from "./HeaderResponsive";
import { _AuthApi } from "api/auth";
function Header({ setLang, direction, lang }) {
  const [ShowNav, setShowNav] = useState(false);

  const [land, setland] = useState("");
  const [showsliderlanguage, setshowsliderlanguage] = useState(false);

  const { t, i18n } = useTranslation("navbar");

  useEffect(() => {
    if (direction === "rtl") {
      setLang("ar");
      setshowsliderlanguage(true);
    } else {
      setLang("en");
      setshowsliderlanguage(false);
    }
    i18n.changeLanguage(land);
    setLang(land);
    i18n.changeLanguage(land);
  }, [direction, land, i18n, setLang]);
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  const navs = [
    { name: t("All Roses"), navlink: "/all-roses" },
    // { name: t("Influencers beautiful creations"), navlink: "/Influencers" },
    { name: t("Shop"), navlink: "/all-roses" },
    { name: t("Occasions"), navlink: "/occasions" },
    { name: t("Gifts"), navlink: "/gifts " },
    { name: t("Subscriptions"), navlink: "/subscription" },
  ];

  return (
    <div dir={direction}>
      {/* first nav */}
      <div>
        <div className="hidden gap-10 sm:flex sm:flex-col ">
          <div className="bg-primary-color">
            <p className="flex justify-center h-5"></p>
          </div>
          <div className="items-center justify-center hidden mx-10 sm:flex sm:text-center sm:justify-between sm:p-5 ">
            <div>
              {" "}
              <NavLink to="/profile">
                <div className="flex gap-3">
                  <img src={account} alt="" />
                  {t("Profile")}
                </div>
              </NavLink>
            </div>
            <div>
              <Link to="/">
                <img src={logo} className="w-40 2xl:w-60" alt="" />
              </Link>
            </div>
            <div className="flex items-center gap-10">
              <div>
                <button
                  onClick={() => {
                    showsliderlanguage ? setland("en") : setland("ar");
                    setshowsliderlanguage(!showsliderlanguage);
                  }}
                >
                  {t("AED")}
                </button>
              </div>

              <NavLink to="/cart">
                <div className="flex gap-3">
                  <img src={cart} alt="" />
                  {t("Cart")}
                </div>
              </NavLink>
              {!_AuthApi.getToken() ? (
                <NavLink to="/account">
                  <div className="flex gap-3">
                    <img src={account} alt="" />
                    {t("Account")}
                  </div>
                </NavLink>
              ) : (
                // <NavLink to="/profile">
                //   <div className="flex gap-3">
                //     <img src={account} alt="" />
                //     {t("Profile")}
                //   </div>
                // </NavLink>
                ""
              )}
            </div>
          </div>
          <div>
            <ul className="flex justify-around">
              {navs.map((nav) => (
                <li className="text-lg bodoni">
                  <NavLink to={nav.navlink}>
                    <li> {nav.name}</li>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* responsive */}
        <HeaderResponsive
          setLang={setLang}
          direction={direction}
          lang={lang}
          ShowNav={ShowNav}
          setShowNav={setShowNav}
        />
      </div>
    </div>
  );
}

export default Header;
