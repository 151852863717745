import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ArrowRight from "../asset/icons/RightArrow.svg";
import ArrowLeft from "../asset/icons/LeftArrow.svg";
import facebook from "../asset/images/Home/index/facbook-hero.png";
import instgram from "../asset/images/Home/index/instgram-hero.png";
import whatsapp from "../asset/images/Home/index/whatsapp-hero.png";
import logoRes from "../asset/images/Home/index/bg2.jpg";
import { useInfluencers } from "hooks/home/useInfluencers";
import { useNavigate } from "react-router-dom";
import { useProduct } from "hooks/home/useProduct";
import { useGetCategory } from "hooks/getCategory/useGetCategory";
import { filterStore } from "store/filterStore";
import { _axios } from "interceptor/http-config";
import { useLinks } from "hooks/links/useLinks";

function Home({ direction }) {
  const navigate = useNavigate();
  const { data: influencer, isLoading } = useInfluencers();
  const { data: products } = useProduct();
  const handleView = (id) => {
    navigate(`influencers-view/view/${id}`);
  };
  const handleView1 = (id) => {
    navigate(`product/view/${id}`);
  };

  const [changingbylang, setchangingbylang] = useState("");

  const { t } = useTranslation("index");

  useEffect(() => {
    if (direction === "rtl") {
      setchangingbylang("mr-[7%]");
    } else {
      setchangingbylang("ml-[7%]");
    }
  }, [direction]);

  const [count, setCount] = React.useState(0);
  const itemsPerSection = 3;
  const itemsPerSectionResponsive = 1;

  const fetchedProducts = products?.products || [];
  const displayedProducts = fetchedProducts.slice(
    count * itemsPerSection,
    (count + 1) * itemsPerSection
  );
  const fetchedProductsResponsive = products?.products || [];
  const displayedProductsResponsive = fetchedProductsResponsive.slice(
    count * itemsPerSectionResponsive,
    (count + 1) * itemsPerSectionResponsive
  );

  const handlePrevButtonClick = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleNextButtonClick = () => {
    const maxCount = Math.ceil(fetchedProducts.length / itemsPerSection) - 1;
    if (count < maxCount) {
      setCount(count + 1);
    }
  };
  const handleNextButtonClickRes = () => {
    const maxCount =
      Math.ceil(fetchedProducts.length / itemsPerSectionResponsive) - 1;
    if (count < maxCount) {
      setCount(count + 1);
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const hanldeNavigate = () => {
    navigate("/all-roses");
  };
  const [filter, setFilter] = filterStore((state) => [
    state.filter,
    state.setFilter,
  ]);
  const { data: category1 } = useGetCategory();
  const handleCategoryClick = (categoryName) => {
    const apiUrl = _axios.get(
      `/user/product?tag=all_roses&color=&category=${categoryName}&price=0,10000&name=`
    );
    setFilter({ ...filter, category: categoryName });
    navigate(`/all-roses?api=${encodeURIComponent(apiUrl)}`);
  };
  const lang = localStorage.getItem("i18nextLng");

  const rotationDegree = lang === "ar" ? 180 : 0;

  const rotationClass = rotationDegree === 180 ? "rotate-180" : "";
  const { data: links } = useLinks();
  return (
    <div className="home">
      <Helmet>
        <title>Garden</title>
        <meta name="description" content="find below the flowers" />
      </Helmet>

      <div className="2xl:my-10">
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="relative hidden h-screen bg-center bg-cover sm:block bg-image"
        >
          {isHovered && (
            <div className="absolute flex justify-center w-full h-full ">
              <div className="flex items-center justify-center w-full text-white bg-opacity-50 bg-black/50">
                <div className="w-[50rem] h-[30rem] p-10 flex flex-col items-center justify-center gap-10 rounded">
                  {/* <h1 className="flex text-5xl bodoni">{t("Flowers")}</h1> */}
                  <p className="flex w-4/5 text-3xl text-center">
                    {t(
                      `"The Garden of Eden is a state of mind and it's not a particular place but a state of consciousness that you can have right here and now."`
                    )}
                  </p>

                  <button
                    className="flex justify-center text-white bg-[#C1CB90] rounded p-3 gap-5 w-1/5 text-xl font-semibold"
                    onClick={hanldeNavigate}
                  >
                    {t("Shop Now")}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="">
            <div className="mx-10">
              <div className="flex-col justify-center hidden h-screen sm:flex">
                <div className="hidden items-center bg-white sm:flex flex-col gap-[5rem] p-5 self-end z-30">
                  <a href={links?.all_links[1]?.url}>
                    <img src={facebook} alt="" />
                  </a>
                  <a href={links?.all_links[0]?.url}>
                    <img src={instgram} alt="" />
                  </a>
                  <a href={links?.all_links[2]?.url}>
                    <img src={whatsapp} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden">
          <img src={logoRes} alt="" />
        </div>
      </div>
      {/* Influencers */}
      {/* <section>
        <div className="flex flex-col gap-10 lg:gap-20">
          <div className="flex justify-center ">
            <h1 className="text-2xl sm:text-4xl">
              {t("Influencers Beautiful Creations")}
            </h1>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-4 gap-y-10 sm:p-10">
            {isLoading && (
              <div className="flex justify-center w-screen ">
                <p className="loader"></p>
              </div>
            )}
            {influencer?.home_influencers?.map((item, index) => (
              <div className="flex flex-col items-center " key={index}>
                <div className="relative p-4 w-100 h-100">
                  <img src={item.image} alt="" className="h-40 md:h-80" />
                  <div
                    className="image-text absolute top-[95%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-black 
                    w-[60%] h-[40%]
                    lg:w-[80%] lg:h-[40%] md:w-[80%] md:h-[40%]
                    sm:w-[80%] sm:h-[40%] bg-white flex flex-col items-center justify-center gap-2"
                  >
                    <p className="flex justify-center text-[10px] text-center sm:text-sm md:text-xl lg:text-2xl">
                      {item.name}
                    </p>
                    <button
                      className="border  bg-[#C1CB90] text-sm sm:w-40 text-[9px] sm:text-xl w-20 p-1 rounded-lg lg:w-40 lg:h-10 lg:text-xl text-white lg:font-semibold"
                      onClick={() => handleView(item.id)}
                    >
                      {t("Shop Now")}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
      {/* Influencers */}
      {/*  SHOP BY CATEGORY*/}
      <section className="">
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="text-2xl sm:text-4xl">{t("SHOP BY CATEGORY")}</h1>
          <p className="text-[#C1CB90] sm:text-3xl text-xl text-center px-2">
            {t("Immerse yourself in the wonderful diversity of flowers.")}
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 gap-y-10 items-center justify-center p-10 rounded-[10px] ">
          {category1?.categories.map((item, index) => (
            <div
              key={index}
              onClick={() => handleCategoryClick(item.title)}
              className="relative block bg-black-rgba w-full h-full w-full z-10 flex items-center justify-center text-[#FFBD80] text-2xl rounded-[20px] "
            >
              <img
                src={item.image}
                alt=""
                className="w-full h-[300px] md:h-[400px] rounded-[20px] "
              />
              <div className="rounded-[20px]  block bg-black-rgba w-full h-full absolute top-0 left-0 w-full z-10 flex items-center justify-center text-[#FFBD80] text-2xl opacity-100 md:opacity-0 md:hover:opacity-100 transition-opacity duration-300">
                {localStorage.getItem("i18nextLng") === "ar"
                  ? item.translation.find(
                      (translation) => translation.locale === "ar"
                    )?.title
                  : item.translation.find(
                      (translation) => translation.locale === "en"
                    )?.title}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/*  SHOP BY CATEGORY*/}

      <section>
        <div>
          {/* slider */}
          <div className="flex flex-col items-center justify-center ">
            <h1 className="text-4xl ">{t("Most Popular")}</h1>
            <p className="py-10 text-xl text-center ">
              {t("Discover the unique aspects of roses")}
            </p>
          </div>
          {isLoading && (
            <div className="flex justify-center w-screen ">
              <p className="loader"></p>
            </div>
          )}
          <div className="hidden md:block">
            <div className="flex justify-around">
              {displayedProducts.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-col items-center justify-center gap-4"
                >
                  <img
                    src={item.image}
                    alt=""
                    onClick={() => handleView1(item.id)}
                    className="w-[450px] h-[450px] rounded-[20px] shadow-lg shadow-gray-500/40"
                  />
                  <p className="text-2xl">{item.name}</p>
                  <p className="text-2xl text-third-color">${item.price}</p>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center pb-10 mt-10">
              <img
                src={ArrowLeft}
                onClick={handlePrevButtonClick}
                className={`sm:mr-10 w-20 h-5 sm:h-10 flex items-center justify-center ${
                  count > 0 ? "" : "hidden opacity-10 bg-[#E1E1E1]"
                } ${rotationClass}`}
                alt=""
              />
              <div className="flex items-center justify-center gap-2">
                <div className="flex items-center justify-center w-2 h-2 bg-gray-300 rounded-full"></div>
                <div className="flex items-center justify-center w-2 h-2 bg-gray-300 rounded-full"></div>
                <div className="flex items-center justify-center w-4 h-4 bg-gray-300 rounded-full">
                  <div className="w-2 h-2 bg-black rounded-full"></div>
                </div>
                <div className="flex items-center justify-center w-2 h-2 bg-gray-300 rounded-full"></div>
                <div className="flex items-center justify-center w-2 h-2 bg-gray-300 rounded-full"></div>
              </div>
              <div className="flex items-center justify-center gap-2">
                {/* Pagination indicators */}
              </div>
              <img
                src={ArrowRight}
                alt=""
                onClick={handleNextButtonClick}
                className={`w-28 h-5 sm:h-10 flex items-center justify-center ${
                  count <
                  Math.ceil(fetchedProducts.length / itemsPerSection) - 1
                    ? ""
                    : " hidden opacity-10 bg-[#E1E1E1]"
                }${rotationClass}`}
              />
            </div>
          </div>
          {/* responsive */}

          <div className="flex items-center justify-center pb-10 mt-10 sm:hidden ">
            <img
              src={ArrowLeft}
              onClick={handlePrevButtonClick}
              className={`sm:mr-10 w-20 h-5 sm:h-10 flex items-center justify-center ${
                count > 0 ? "" : "opacity-10 bg-[#E1E1E1]"
              }${rotationClass}`}
              alt=""
            />
            <div className="flex items-center justify-around">
              {displayedProductsResponsive.map((item) => (
                <div className="flex flex-col items-center justify-center gap-4">
                  <a href="#">
                    <img
                      src={item.image}
                      alt=""
                      className="w-80 h-80 object-contain rounded-[10px] shadow-lg shadow-gray-500/40"
                    />
                  </a>
                  <p className="text-2xl">{item.name}</p>
                  <p className="text-2xl text-third-color">${item.price}</p>
                </div>
              ))}
            </div>
            <img
              src={ArrowRight}
              alt=""
              onClick={handleNextButtonClickRes}
              className={`w-28 h-5 sm:h-10 flex items-center justify-center ${
                count < fetchedProducts.length - 1
                  ? ""
                  : "opacity-10 bg-[#E1E1E1]"
              }${rotationClass}`}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
