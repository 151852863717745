import React, { useEffect, useState } from "react";
import searchIcon from "../../asset/icons/searchIcon.svg";
import rangeSlider from "../../asset/icons/sliderRangeIcon.svg";
import { useGetColors } from "hooks/getColors/useGetColors";
import { useGetCategory } from "hooks/getCategory/useGetCategory";
import { useFilter } from "hooks/filter/useFilter";
import { Slider } from "@mui/material";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const { t } = useTranslation("index");
  const { setFilter, filter } = useFilter();

  const [showFilter, setShowFilter] = useState(true);

  const handleSliderChange = (event) => {
    setFilter({ ...filter, price: event.target.value });
  };

  const handleColorClick = (colorName) => {
    setFilter({ ...filter, color: colorName });
  };

  const handleCategoryClick = (categoryName) => {
    setFilter({ ...filter, category: categoryName });
  };

  const { data: color1 } = useGetColors();
  const { data: category1 } = useGetCategory();

  const handleSearch = useDebounce((e) => {
    const newName = e.target.value;
    setFilter({ ...filter, name: newName });
  }, 1000);
  const debouncedIncreaseQuantity = useDebounce(handleSearch);

  useEffect(() => {
    if (category1)
      console.log(
        category1?.categories?.translation?.filter(
          (translation) => translation?.locale === "ar"
        )(0).title
      );
  }, [category1]);

  return (
    <>
      <div className="block mx-5 my-2 md:hidden">
        <button
          className="border  bg-[#C1CB90] text-sm  text-[9px]  w-full  p-2   rounded-lg"
          onClick={() => setShowFilter(!showFilter)}
        >
          {showFilter ? t("Close Filter") : t("Show Filter")}
        </button>
      </div>
      {showFilter && (
        <>
          <div className="flex flex-col w-full gap-5 p-10 my-5 border  border-1 md:w-80 md:my-0">
            <div className="relative ">
              <input
                type="search"
                placeholder={t("Search products")}
                onChange={debouncedIncreaseQuantity}
                className="py-2 pl-4 pr-10 border-2 border-gray-400 rounded-lg w-60 focus:outline-none focus:border-blue-500"
              />

              <div className="absolute top-0 right-0 px-3 py-2">
                <img src={searchIcon} alt="" />
              </div>
            </div>

            <div className="flex justify-between">
              <label
                htmlFor="default-range"
                className="block text-xl font-medium text-gray-900 "
              >
                {t("Price")}
              </label>
              <img src={rangeSlider} alt="" />
            </div>

            <Slider
              min={0}
              max={20000}
              defaultValue={filter.price}
              onChange={handleSliderChange}
              sx={{ color: "#FFBD80" }}
            />

            <div className="flex justify-between">
              <label
                htmlFor="default-range"
                className="block text-xl font-medium text-gray-900 "
              >
                {t("Range")}
              </label>
              <p>
                ${filter.price[0]}-${filter.price[1]}
              </p>
            </div>
            <h6 className="text-xl font-bold">{t("Colors")}</h6>
            {color1?.colors?.map((item, index) => (
              <div className="flex flex-col gap-8 cursor-pointer" key={item.id}>
                <p
                  name={item.title}
                  onClick={() => handleColorClick(item.title)}
                >
                  {localStorage.getItem("i18nextLng") === "ar"
                    ? item.translation.find(
                        (translation) => translation.locale === "ar"
                      )?.title
                    : item.translation.find(
                        (translation) => translation.locale === "en"
                      )?.title}
                </p>
              </div>
            ))}

            <h6 className="text-xl font-bold">{t("Categories")}</h6>
            {category1?.categories?.map((item, index) => (
              <div className="flex flex-col gap-8 cursor-pointer" key={item.id}>
                <p
                  name={item.title}
                  onClick={() => handleCategoryClick(item.title)}
                >
                  {localStorage.getItem("i18nextLng") === "ar"
                    ? item.translation.find(
                        (translation) => translation.locale === "ar"
                      )?.title
                    : item.translation.find(
                        (translation) => translation.locale === "en"
                      )?.title}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SideBar;
{
  /* {Object.values(item)
                  .slice(1)
                  .map((bodyItem, bodyIndex) => (
                    <div key={bodyIndex}>
                      <a href={`#${bodyItem}`}>{bodyItem}</a>
                    </div>
                  ))} */
}
