import create from "zustand";

export const categoryStore = create((set) => ({
  editedID: null,

  setEditedID: (id) => {
    set(() => ({ editedID: id }));
  },
}));

//   const [editedID, setEditedID] = categoryStore((state) => [
//     state.editedID,
//     state.setEditedID,
//   ]);
//   const handleEdit = (id) => {
//     setEditedID(id);
//   };
//       {
//         editedID && <ReaalStateUpdate id={editedID} />;
//       }
