import { _AuthApi } from "api/auth";
import { _axios } from "../../interceptor/http-config";

const access_token = _AuthApi.getToken();
export const _Cart = {
  index: async () => {
    const formData = new FormData();
    formData.append("session_id", localStorage.getItem("session_id"));
    return _axios
      .post(`/user/cart`, formData, {
        headers: {
          Authorization: access_token ? `Bearer ${access_token}` : "",
        },
      })
      .then((res) => res.data);
  },
  post: (data, setLoading, navigate) =>
    _axios.post("user/cart/checkOut", data).then((res) => {
      setLoading(true);
      // navigate(-1);
    }),
};
