import { useQuery } from "react-query";
import { _Influencer } from "api/influencer/influencer";

export const useInfluencer = (id) => {
  const { data, isLoading } = useQuery(
    ["influencer"],
    () => _Influencer.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
